import ResultDisplay from "./components/ResultDisplay";
import { useEffect, useState } from "react";
import {
  getAllWizardEntries,
  getTuner,
  deleteTunerAndEntry,
} from "actions/tuners";
import toast from "react-hot-toast";


const TunersEntry = () => {
  const [entries, setEntries] = useState([]);
  const [currentEntryIndex, setCurrentEntryIndex] = useState(0);
  const [tuner, setTuner] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false); // State to track deletion process

  useEffect(() => {
    // Fetch entries from the server
    getAllWizardEntries()
      .then((entries) => {
        setEntries(entries);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  useEffect(() => {
    if (entries.length > 0) {
      const currentEntry = entries[currentEntryIndex];
      const userId = currentEntry.user; // Assuming "user" is the key for the user value in the entry object
      console.log(userId);
      if (userId) {
        // Make getTuner request
        getTuner(userId)
          .then((tuner) => {
            setTuner(tuner);
            console.log(tuner);
          })
          .catch((err) => {
            console.log(err.message);
          });
      }
    }
  }, [entries, currentEntryIndex]);

  const handleNextEntry = () => {
    if (currentEntryIndex < entries.length - 1) {
      setCurrentEntryIndex(currentEntryIndex + 1);
    }
  };

  const handlePreviousEntry = () => {
    if (currentEntryIndex > 0) {
      setCurrentEntryIndex(currentEntryIndex - 1);
    }
  };

  const handleDelete = async () => {
    const currentEntry = entries[currentEntryIndex];
    const userId = currentEntry._id; // Assuming "user" is the key for the user value in the entry object

    setIsDeleting(true); // Set loading state to true before the delete operation

    try {
      await deleteTunerAndEntry(userId);

      // After deletion, update state to remove the deleted entry
      const updatedEntries = entries.filter(
        (entry, index) => index !== currentEntryIndex
      );
      setEntries(updatedEntries);
      if (currentEntryIndex > 0) {
        setCurrentEntryIndex(currentEntryIndex - 1);
      }
      setTuner(null); // Clear tuner info
      toast.success("Tuner profile and entry deleted successfully");
      console.log("User and entry deleted successfully");
    } catch (err) {
      toast.error("Tuner profile and entry delete failed");
      console.log(err.message);
    } finally {
      setIsDeleting(false); // Set loading state to false after the operation is completed
    }
  };

  const formatKey = (key) => {
    return key
      .replace(/_/g, " ")
      .replace(/\b\w/g, (match) => match.toUpperCase());
  };

  const excludedKeys = ["updatedAt", "createdAt", "__v"];
  const currentEntry = entries[currentEntryIndex];

  return (
    <div className="mt-3 grid h-full grid-cols-1 gap-5">
      <div className="col-span-1 h-fit w-full">
        {entries.length > 0 ? (
          <div>
            <div className="mb-4">
              <button
                onClick={handleDelete} // Attach delete function to button
                className={`rounded bg-red-500 px-4 py-2 text-white hover:bg-red-600 ${
                  isDeleting ? "cursor-not-allowed opacity-50" : ""
                }`}
                disabled={isDeleting} // Disable button when deleting
              >
                {isDeleting ? "Deleting..." : "Delete Account"}{" "}
                {/* Show loading text */}
              </button>
            </div>

            <ResultDisplay
              entries={entries}
              currentEntryIndex={currentEntryIndex}
              currentEntry={currentEntry}
              formatKey={formatKey}
              excludedKeys={excludedKeys}
              tuner={tuner}
              handleNextEntry={handleNextEntry}
              handlePreviousEntry={handlePreviousEntry}
            />

            {/* Navigation buttons */}
            <div className="mt-4 flex justify-between">
              <button
                onClick={handlePreviousEntry}
                disabled={currentEntryIndex === 0 || isDeleting} // Disable if deleting or no previous entry
                className="rounded bg-gray-500 px-4 py-2 text-white hover:bg-gray-600 disabled:opacity-50"
              >
                Previous
              </button>

              <button
                onClick={handleNextEntry}
                disabled={
                  currentEntryIndex === entries.length - 1 || isDeleting
                } // Disable if deleting or no next entry
                className="rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600 disabled:opacity-50"
              >
                Next
              </button>
            </div>
          </div>
        ) : (
          <p className="dark:text-white">No entries found</p>
        )}
      </div>
    </div>
  );
};

export default TunersEntry;
